//==============================================================================
// Look Inside
//
// Opens a modal with a select set of book pages that the customer can look
// through before purchasing.
//==============================================================================
import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { clsHelper } from '../../../../utilities/class-name-helper';

import { Modal, ModalBody, ModalFooter, ModalHeader } from '@msdyn365-commerce-modules/utilities';

//==============================================================================
// INTERFACES
//==============================================================================
export interface ILookInsideProps {
    buttonLabel: string;
}

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'look-inside';
const cls = (fragment?: string) => clsHelper(BASE_CLASS, fragment);

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * LookInside component
 * @extends {React.Component<ILookInsideProps>}
 */
//==============================================================================
@observer
class LookInside extends React.Component<ILookInsideProps> {
    //==========================================================================
    // VARIABLES
    //==========================================================================
    public lookInsideRef: React.RefObject<any>;
    @observable private isOpen: boolean = false;

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================
    //------------------------------------------------------
    // Constructor
    //------------------------------------------------------
    constructor(props: ILookInsideProps) {
        super(props);
        this.lookInsideRef = React.createRef();
    }

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element {
        const { buttonLabel } = this.props;

            return (
                <div className={BASE_CLASS}>
                    <button className={cls('button')} ref={this.lookInsideRef} onClick={this._toggle} aria-label={buttonLabel}>
                        {buttonLabel}
                    </button>
                    <Modal
                        autoFocus={true}
                        fade={false}
                        returnFocusRef={this.lookInsideRef}
                        isOpen={this.isOpen}
                        toggle={this._toggle}
                        className={cls('modal')}
                        horizontalPosition={'center'}
                        verticalPosition={'center'}
                    >
                        <ModalHeader toggle={this._toggle} />
                        <ModalBody>
                            Look Inside Placeholder Content
                        </ModalBody>
                        <ModalFooter />
                    </Modal>
                </div>
            );
    }

    //==========================================================================
    // PRIVATE METHODS
    //==========================================================================
    //------------------------------------------------------
    // Toggle modal
    //------------------------------------------------------
    private readonly _toggle = (): void => {
        this.isOpen = !this.isOpen;
    }
}

export default LookInside;
